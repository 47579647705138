.avatar {
  position: fixed;
  overflow: hidden;
  z-index: -2;
}

.avatar.about {
  height: 89vh;
  bottom: -8vh;
  left: 50%;
  transform: translateX(-50%);
}

.avatar.skills {
  height: 100vh;
  bottom: -110vh;
  left: -1.5rem;
  transform: translateX(0%) scaleX(-1);
}

.shadow-overlay-skills {
  height: 100vh;
  width: 100vh;
  display: block;
  position: fixed;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.25),
    rgba(255, 255, 255, 0)
  );
  z-index: -1;
}

@media (max-width: 1100px) {
  .avatar.skills {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
}

@media (max-width: 700px) {
  .avatar.about {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
}
